import { graphql, Link, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import Layout from '../components/layout'
// import myselfAndDaughter from '../images/anna_and_child_cropped.jpg'
import endlessWaves from '../assets/icons/endless-clouds.svg'
import teddyGirl from '../assets/illustrations/teddy.svg'

const aboutMe = () => {
  const image = useStaticQuery(graphql`
    query {
      myselfAndDaughter: file(relativePath: { eq: "anna_and_child_cropped.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <div className="m-auto xl:max-w-6xl lg:mt-12">
        <div id="avatar-top" className="lg:flex lg:flex-row">
          <aside className="flex-grow">
            <div
              id="avatar-block"
              className="relative w-1/2 m-auto mt-4 transform -translate-x-4 bg-right bg-ugly-lilas-hovered fancy-borders"
            >
              <Img
                fluid={image.myselfAndDaughter.childImageSharp.fluid}
                alt="Moi et ma fille"
                className="w-64 m-auto transform translate-x-8 translate-y-2 fancy-borders"
              />
            </div>

            <p className="w-3/4 m-auto mt-4 text-xl font-header">
              Je m’appelle Anna, je suis heureuse de vous aider à retrouver avec votre enfant le chemin vers
              le pays du sommeil.
            </p>
          </aside>

          <section id="path-taken" className="m-auto mx-4 mt-4 text-xl font-muli">
            <h3 className="mb-4 text-3xl underlined">Mon parcours</h3>
            <p>&bull; Consultante Certifiée dans le sommeil des enfants de 0 à 6 ans.</p>
            <br />
            <p>
              &bull; Diplômée du prestigieux « Family Sleep Institute »,{' '}
              <a href="https://familysleepinstitute.com/" target="_blank" rel="noopener noreferrer">
                un établissement américain
              </a>{' '}
            </p>
            <br />
            <p>
              &bull; J’ai dû accomplir plus de 250 heures de formation, du travail personnel et j’ai réalisé
              mon Projet Final, qui exigeait un résultat favorable avec 3 familles ou plus.
            </p>
            <br />
            <p>
              &bull; J’ai également suivi les cours sur la sécurité du sommeil de l’Enfant, chez American
              Academy of Pediatrics, et les bases de lactation chez{' '}
              <a href="/certifications.pdf" target="_blank" rel="noopener noreferrer">
                Lactation Education Ressources
              </a>
              .
            </p>
            <br />
            <p>
              &bull; Je fais partie du réseau professionnel des consultants, ce qui me permet de continuer à
              me former, au sein du programme de « Family Sleep Institute »
            </p>
            <br />
            <p>
              &bull; Les succès des familles, avec qui j’ai travaillé sont mes meilleures récompenses et des
              confirmations de l’efficacité de mon approche. Trouvez-les dans la{' '}
              <Link to="/feedbacks"> rubrique Témoignages</Link>.
            </p>
          </section>
        </div>

        <section id="my-history" className="m-auto mx-4 mt-4 text-xl font-muli">
          <h3 className="mb-4 text-3xl underlined">Mon histoire</h3>
          <p>
            Ma passion pour cette activité m’est venue grâce à mon expérience de maman d’une petite princesse,
            qui a elle aussi eu besoin de retrouver son chemin vers le Pays du Sommeil...
          </p>
          <br />

          <p>
            Mon époux et moi avons aussi traversé une période difficile de couchers qui s’éternisent et de
            multiples réveils nocturnes. Notre fille avait besoin de notre présence pour se rendormir.
            <br />
            Au petit matin, nous étions complètement épuisés, déboussolés et nerveux. Nous avons tenté de
            consulter notre pédiatre de famille et d’autres spécialistes, sans que personne ne puisse nous
            apporter de solution efficace.
          </p>

          <p className="inline-block px-4 my-4 text-2xl italic text-white rounded-lg bg-lilas font-muli">
            Est-ce que vous vous reconnaissez dans ces propos ?
          </p>

          <div id="large-screen-offset">
            <p>
              Après 3 longs mois de souffrances, nous avons pris la décision de nous adresser à une
              consultante spécialisée dans le sommeil des enfants.
            </p>
            <br />

            <div id="larger-screen" className="flex flex-row">
              <div id="larger-screen-nested" className="flex flex-col">
                <p>
                  Il nous a fallu deux semaines de travail exigeant une implication permanente et une stricte
                  discipline, pour devenir enfin les témoins d’un véritable miracle. Notre enfant
                  s&apos;endort seul, paisiblement, dans sa chambre, et fait des nuits d’un sommeil continu de
                  12 à 13 heures. Ses siestes sont de qualité et ses journées sont emplies d’énormément de
                  joie.
                </p>
                <br />

                <p>
                  Un miracle ? Non, plutôt le résultat d’une méthode, fruit de nombreuses recherches
                  scientifiques poussées. Aujourd’hui, je suis ravie de partager ces connaissances avec vous.
                </p>
              </div>
              <aside>
                <img className="hidden lg:block" src={teddyGirl} alt="fille détendue" />
              </aside>
            </div>
          </div>

          <p className="inline-block px-4 my-4 text-2xl italic text-white rounded-lg bg-lilas font-muli">
            Et vous, êtes-vous prêts pour cette expérience ?
          </p>
        </section>
      </div>

      <div id="embed-video" className="md:w-5/6 md:mx-auto xl:max-w-5xl xl:mx-auto">
        <br />
        <h3 className="my-6 text-2xl text-center font-muli">
          Afin de me connaître davantage, ainsi que mon parcours vers le métier de consultante en sommeil des
          enfants, je vous invite à regarder cette vidéo:
        </h3>
        <iframe
          className="w-full max-w-3xl mx-auto lg:w-9/12 h-80"
          src="https://www.youtube.com/embed/fMPlBBlIxUQ"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>

      <div
        style={{ backgroundImage: `url(${endlessWaves})` }}
        className="flex items-center justify-center w-full h-40 mt-8 text-2xl bg-center bg-repeat font-muli"
      >
        <button className="p-2 bg-white rounded-full">
          <Link
            to="/contact-form/"
            className="inline-block p-4 border-2 border-dashed rounded-full banner-call-me border-lilas"
          >
            Appelez-moi dès aujourd’hui
          </Link>
        </button>
      </div>
    </Layout>
  )
}

export default aboutMe
